






















import { Component, Mixins } from 'vue-property-decorator';
import { FormSubmissionResult } from 'client-website-ts-library/types';
import { Map } from 'client-website-ts-library/components';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';
import { Client } from 'client-website-ts-library/services';

@Component({
  components: {
    Map,
    Form,
  },
})
export default class OffMarketRegistration extends Mixins(View) {
  private isReauth = false;

  mounted() {
    this.isReauth = this.$route.query?.reauth === 'true';
  }

  handleSubmitted(result?: FormSubmissionResult) {
    console.log(result);

    if (result && result.GrantedToken) {
      Client.SetAuthToken(result.GrantedToken);

      let next = '/off-market/properties-for-sale';

      if (this.$route.query?.next) {
        next = this.$route.query?.next as string;
      }

      this.$router.push(next);
    }
  }
}
